<template>
  <b-form-select
    v-model="selected"
    v-bind="{
      name,
      value,
      options,
      disabled,
      placeholder,
      valueField,
      textField
    }"
    @change="onChange"
  >

    <template
      #first
      v-if="placeholder"
    >
      <b-form-select-option v-bind="{
          value: null
        }"> {{ placeholder }}
      </b-form-select-option>
    </template>

  </b-form-select>
</template>

<script>
import {
  BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'CommonSelect',
  components: {
    BFormSelect,
  },
  props: {
    name: {
      type: String,
      default() {
        return ''
      },
    },
    placeholder: {
      type: String,
      default() {
        return ''
      },
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    valueField: {
      type: String,
      default() {
        return 'value'
      },
    },
    textField: {
      type: String,
      default() {
        return 'text'
      },
    },
    value: {
      type: [Number, String],
      default() {
        return null
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      selected: this.value,
    }
  },
  updated() {
    this.selected = this.value
  },
  methods: {
    onChange(value) {
      this.$emit('change', { [this.name]: value })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
