var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-select',_vm._b({on:{"change":_vm.onChange},scopedSlots:_vm._u([(_vm.placeholder)?{key:"first",fn:function(){return [_c('b-form-select-option',_vm._b({},'b-form-select-option',{
        value: null
      },false),[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'b-form-select',{
    name: _vm.name,
    value: _vm.value,
    options: _vm.options,
    disabled: _vm.disabled,
    placeholder: _vm.placeholder,
    valueField: _vm.valueField,
    textField: _vm.textField
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }